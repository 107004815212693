import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import SubCategoriesCreate from './SubCategoriesCreate';
import SubCategoriesUpdate from './SubCategoriesUpdate';

const SubCategories = Loadable(lazy(() => import('./SubCategories')));

const subCategoryRoutes = [
  { path: '/form-sub-categories', element: <SubCategories type={1} />, auth: authRoles.admin },
  { path: '/print-sub-categories', element: <SubCategories type={2} />, auth: authRoles.admin },
  { path: '/link-sub-categories', element: <SubCategories type={3} />, auth: authRoles.admin },
  { path: '/sub-categories/create/:type', element: <SubCategoriesCreate />, auth: authRoles.admin },
  { path: '/sub-categories/edit/:subCategoryId/:type', element: <SubCategoriesUpdate />, auth: authRoles.admin },

];

export default subCategoryRoutes;
