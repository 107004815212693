import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import QrCode from './QrCode';

const Form = Loadable(lazy(() => import('./QrCode')));

const QrCodeRoutes = [
  { path: '/qr-code/:printerId', element: < QrCode/> },
];
    
export default QrCodeRoutes;
