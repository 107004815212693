import React, { useContext, useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { useParams } from 'react-router-dom';
import { convertToPdf, getSettings, getUserFormDetails, printFile } from 'app/api/printNode';
import MaxWidthDialog from '../material-kit/dialog/OptimalSizeDialog';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import enTranslations from '../../utils/en';
import arTranslations from '../../utils/ar';
import { LanguageContext } from 'app/contexts/LanguageContext';


function GenerateImage() {
    // inital login credentials
    const initialValues = {
        printerId: printerId,
        count: 1,
        number: 0,
        color: 'gray-scale',
        file: [],
    };


    const { userFormId, printerId } = useParams();
    const canvasRef = useRef(null);
    const [price, setPrice] = useState({});
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [state, setState] = useState(initialValues);
    const [canvas, setCanvas] = useState(null); // Use state to set the canvas asynchronously
    const [form, setForm] = useState({});
    const { language } = useContext(LanguageContext);
    const translations = (language == 'en') ? enTranslations : arTranslations;


    const exportCanvas = () => {


        setLoading(true);
        const dataURL = canvas.toDataURL({ format: 'png', quality: 0.8 });
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'canvas.png';
        document.getElementById("print-preview").src = dataURL;
        // link.click();

        const blob = dataURLToBlob(dataURL);
        // console.log(new File([blob], "name"));

        let formData = new FormData(); //formdata object
        formData.append('file', new File([blob], "test.jpg", { type: 'image/jpeg' }));
        // formData.append('file', blob);

        convertToPdf(formData)
            .then((res) => {
                const printData = {
                    file_url: res.data.result.pdf_path,
                    isColor: state.color != 'gray-scale',
                    count: parseInt(state.count),
                    printer_id: printerId,
                };

                setState({ ...state, number: res.data.result.number });

                printFile(printData).then((response) => {
                    console.log(response);
                    setLoading(false);
                    setIsOpen(true);

                });
            })
            .catch((e) => {
                console.log(e);
                if (e.response.status == 422) {
                    Object.entries(e.response.data.result).forEach(([key, value]) => alert(value));

                    //             setLoading(false);
                }
            });
    };

    function dataURLToBlob(dataURL) {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    useEffect(() => {

        getUserFormDetails(userFormId).then((res) => {
            setForm(res.data.result.data);

            const newCanvas = new fabric.Canvas(canvasRef.current);
            setCanvas(newCanvas);
            fabric.Image.fromURL(res.data.result.data.form.image, (img) => {
                // Set the image as the canvas background
                newCanvas.setHeight(img.height);
                newCanvas.setWidth(img.width);

                img.set({
                    selectable: false, // Make the image non-selectable
                    // Other image properties...
                });
                // image.set({ left: 50, top: 50, width: 200, height: 200 });
                newCanvas.add(img);
                res.data.result.data.user_form.map(item => {
                    if (item.form_field_id.field_type == 'Checkbox' && item.value == '1') {
                        const tick = new fabric.Path('M16.972 6.251c-.967-.538-2.185-.188-2.72.777l-3.713 6.682-2.125-2.125c-.781-.781-2.047-.781-2.828 0-.781.781-.781 2.047 0 2.828l4 4c.378.379.888.587 1.414.587l.277-.02c.621-.087 1.166-.46 1.471-1.009l5-9c.537-.966.189-2.183-.776-2.72z', {
                            left: parseInt(item.form_field_id.x),
                            top: parseInt(item.form_field_id.y),
                            stroke: 'green',
                            strokeWidth: 2,
                            selectable: false,
                        });

                        tick.set({
                            selectable: false, // Make the image non-selectable
                            // Other image properties...
                        });


                        newCanvas.add(tick);
                    } else {

                        console.log(item);
                        const text = new fabric.Text(item.value, {
                            left: parseInt(item.form_field_id.x),
                            top: parseInt(item.form_field_id.y),
                            fontSize: parseInt(item.form_field_id.font_size),
                            // 16px to 18px default
                            fill: 'black',
                        });
                        text.set({
                            selectable: false, // Make the image non-selectable
                            // Other image properties...
                        });
                        newCanvas.add(text);
                    }
                })
                newCanvas.renderAll();

                const dataURL = newCanvas.toDataURL({ format: 'png', quality: 0.8 });
                document.getElementById('print-preview').src = dataURL;



            }, { crossOrigin: 'anonymous' });

        })

        getSettings().then((res) => {
            setPrice(res.data.result.data[0]);
        });

    }, []);

    return (
        <div>

            {/* <button onClick={() => exportCanvas(canvas)}>Export Canvas</button> */}

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >

                <Box key={0}>

                    <center>
                        <Typography
                            sx={{
                                padding: '20px 0px'
                            }}
                            variant="h4"
                        >
                            {'Print Details'}
                        </Typography>
                    </center>
                </Box>
                <Grid
                    container
                    item
                    sx={{
                        width: '100%',
                        padding: '0 15px',
                        boxSizing: 'border-box',
                        margin: '0'
                    }}
                    gap={'20px'}
                    spacing={5}
                    justifyContent="center"
                    alignItems="center"

                >
                    <Formik
                        // validate={validateForm}
                        // onSubmit={handleFormSubmit}
                        initialValues={state}
                    // validationSchema={validationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                {/* <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="gray-scale"
                                    name="color"
                                    onBlur={handleBlur}
                                    onChange={(event, newValue) => {
                                        setState({ ...state, color: newValue });
                                    }}
                                    helperText={touched.color && errors.color}
                                    error={Boolean(touched.color && errors.color)}
                                    sx={{ mb: 1.5 }}
                                >
                                    <FormControlLabel value="color" control={<Radio />} label={translations.color} />
                                    <FormControlLabel value="gray-scale" control={<Radio />} label={translations.gray_scale} />
                                </RadioGroup> */}

                                {/* <TextField
                                    fullWidth
                                    size="small"
                                    type="number"
                                    name="count"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    label={translations.copy}
                                    variant="outlined"
                                    value={state.count}
                                    sx={{
                                        marginBottom: '10px'
                                    }}
                                    onChange={(event, newValue) => {
                                        setFieldValue('count', event.target.value);
                                        setState({ ...state, count: event.target.value });
                                    }}
                                    helperText={touched.count && errors.count}
                                    error={Boolean(errors.count && touched.count)}
                                /> */}

                                {/* <canvas ref={canvasRef} /> */}
                                <img
                                    id='print-preview'
                                    src=''
                                    alt='print preview'
                                    style={{maxWidth : '100%'}}
                                />
                                <LoadingButton
                                    type="submit"
                                    color="primary"
                                    onClick={() => exportCanvas(canvas)}
                                    loading={loading}
                                    variant="contained"
                                    sx={{
                                        my: 2,
                                        marginLeft: '40%'
                                    }}
                                >
                                    {translations.print}
                                </LoadingButton>
                            </form>
                        )}
                    </Formik>
                </Grid>
                <MaxWidthDialog
                    open={isOpen}
                    setIsOpen={setIsOpen}
                    state={state}
                    setting={price}
                    files={{}}
                    printerId={printerId}
                    isForm={true}
                />
            </Grid >

        </div>

    );
}

export default GenerateImage;
