import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Printers = Loadable(lazy(() => import('./Printers')));

const printerRoutes = [
  { path: '/printers', element: <Printers />, auth: authRoles.admin },
];

export default printerRoutes;
