const FrontLayoutSettings = {
  leftSidebar: {
    show: false,
    mode: 'close', // full, close, compact, mobile,
    theme: 'blue', // View all valid theme colors inside MatxTheme/themeColors.js
    bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg',
  },
  topbar: {
    show: false,
    fixed: true,
    theme: 'whiteBlue', // View all valid theme colors inside MatxTheme/themeColors.js
  },
};

export default FrontLayoutSettings;
