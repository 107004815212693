import { Box, Card, Grid, styled } from '@mui/material';
import { H1, H3, H4, Span } from 'app/components/Typography';
import React from 'react'
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';

function QrCode() {

    const { printerId } = useParams();

    const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

    const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

    const JWTRoot = styled(JustifyBox)(() => ({
        background: '#920497',
        minHeight: '100% !important',
        flexDirection: 'column',
        '& .card': {
            flexGrow: 1,
            maxWidth: 1000,
            // height: 300,
            margin: '3rem',
            padding: '3rem',
            display: 'flex',
            borderRadius: 12,
            alignItems: 'center',
        },
    }));


    return (
        <JWTRoot>
            <Card style={{
                flexDirection: 'column'
            }} className="card">
                <Grid container width='100%' sx={12} justifyContent="center">

                    <Span>
                        <img style={{ marginInline: '100px' }} src="/assets/images/logo.png" width="130px" alt="" />
                        <br />
                        <br />
                        <H1 style={{direction: 'rtl'}}>
                            الطباعة السريعة :
                        </H1>
                        <H1 style={{direction: 'rtl'}}>
                            - امس الكود من خلال كامرات هاتفك
                        </H1>
                        <H1 style={{direction: 'rtl'}}>
                            - ارفق الملف
                        </H1>
                        <H1 style={{direction: 'rtl'}}>
                            - أطبع

                        </H1>
                    </Span>

                </Grid>
                <br />
                <br />
                <Grid container width='100%' sx={12} justifyContent="center">
                    <Span marginInline={12}>
                        <QRCode
                            id={printerId}
                            title='Qr Code'
                            value={'http://leza-solutions.codeflix.in/print-form/' + printerId}
                            size={200}
                            level={"H"}
                            includeMagin={true}
                        />
                    </Span>

                </Grid>
            </Card>
        </JWTRoot>
    )
}

export default QrCode
