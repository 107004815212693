export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const projectTitle = 'Print / طبع'

// api key for print node
export const printNodeAPIKey1 = "_GebDcNjRN_DSRvPLAVx4upYZPySTlmxPdbuKg7UJJw" // for dhaval.sn007@gmail.com
export const printNodeAPIKey = "lel5VzKOQEJUAlXsXPFlcgFlrWziakf4PtGd0ofFkHM" // for info@codeflixwe
export const printNodeAPIUrl = "https://api.printnode.com"
export const baseUrl = "https://print-api.codeflixweb.com/api"
