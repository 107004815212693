import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const PrintJobs = Loadable(lazy(() => import('./PrintJobs')));

const printJobsRoutes = [
  { path: '/reports', element: <PrintJobs />, auth: authRoles.admin },
];

export default printJobsRoutes;
