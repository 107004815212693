import { MatxSuspense } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { MatxLayouts } from './index';

const FrontLayout = (props) => {
  const { settings } = useSettings();
  const Layout = MatxLayouts[settings.frontLayout];

  return (
    <MatxSuspense>
      {/* <Layout {...props} /> */}
      <Layout  />
    </MatxSuspense>
  );
};

export default FrontLayout;
