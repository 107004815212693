const translations = {
    print: 'PRINT',
    edit_form: 'EDIT FORM',
    ready_form: 'READY FORM',
    payment_center: 'PAYMENT CENTER',
    how_do_i_print: 'How Do I PRINT?',
    printing_can_be_done_through_only_three_steps: 'Printing can be done through only Three steps',
    choice_the_printing_type: 'choice the printing type (photo print or print  on a form with the option to write on the form before printing)',
    click_print: ' click " print "',
    take_your_paper_from_the_self_service_machine: 'take your paper from the self service machine',
    printing_will_now_take_place_please_take_the_papers_when_the_printing_process_is_finished: 'Printing will now take place. Please take the papers when the printing process is finished',
    summary: 'Summary',
    color: 'Color :',
    copy: 'Copy :',
    the_price_of_one_copy: 'The price of one copy :',
    final_price: 'Final price :',
    ok: 'Ok',
    number_of_pages: 'Number Of Pages :',
    category: "Category",
    please_upload_the_document: "Please upload the document",
    gray_scale: 'Gray Scale'

  };
  
  export default translations;