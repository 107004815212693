import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import FormsCreate from './FormsCreate';
import FileFormsUpdate from './FileFormsUpdate';
import FormsFieldUpdate from './FormsFieldUpdate';
import PdfFormBuilder from './PdfFormBuilder';

const Forms = Loadable(lazy(() => import('./Forms')));

const formsRoutes = [
  { path: '/ready-forms', element: <Forms type={1} />, auth: authRoles.admin },
  { path: '/print-forms', element: <Forms type={2} />, auth: authRoles.admin },
  { path: '/forms/create/:type', element: <FormsCreate />, auth: authRoles.admin },
  { path: '/forms/edit/:formId/:type', element: <FileFormsUpdate />, auth: authRoles.admin },
  { path: '/forms/field-edit/:formId', element: <FormsFieldUpdate />, auth: authRoles.admin },
  // { path: '/forms/field-edit/:formId', element: <PdfFormBuilder />, auth: authRoles.admin },

];

export default formsRoutes;
