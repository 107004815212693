import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';


function DragAndDropFormField({ setOpen, forms, setPosition } = setOpen) {
    const src = forms.image;

    const img = new Image();
    img.src = src;
    console.log(img);
    // console.log('width: '+img.width);

    const ratio = img.height / img.width;
    // console.log('ratio: '+ratio);
    const width = img.height;
    const height = img.width;
    // const height = 700;
    // const width = 1000;
    // console.log('modified height: '+height);
    // console.log('modeified width: '+width); 

    
    const canvasRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);
    const [endX, setEndX] = useState(null);
    const [endY, setEndY] = useState(null);

    useEffect(() => {
        // const canvas = canvasRef.current;
        // const ctx = canvas.getContext('2d');
        // ctx.drawImage(img, 0, 0, height, width);
        // if (forms.hasOwnProperty('form_field')) {
        //     forms.form_field.map((item) => {
        //         drawCreatedSquare(item.x, item.y, item.w, item.h);
        //     })
            
        // }
        
    }, [])


    const handleMouseDown = (event) => {
        const { layerX, layerY } = event.nativeEvent;
        setIsMouseDown(true);
        setStartX(layerX);
        setStartY(layerY);
    };

    const handleMouseMove = (event) => {
        if (!isMouseDown) return;
        const { offsetX, offsetY } = event.nativeEvent;
        setEndX(offsetX);
        setEndY(offsetY);
        drawSquare();
    };

    const handleMouseUp = (event) => {
        const { layerX, layerY } = event.nativeEvent;
        setIsMouseDown(false);
        setEndX(layerX);
        setEndY(layerY);
        drawSquare();
        setPosition({ x: startX, y: startY, w: endX, h: endY })
        setOpen(true);

    };

    const handleLoadCanvas = () => {
        const canvas = fabric.Canvas(canvasRef.current);

        // Set canvas dimensions to fit the image
        canvas.setWidth(width);
        canvas.setHeight(height);
      
        // Load the image onto the canvas
        fabric.Image.fromURL(src, (img) => {
          img.set({ selectable: false });
          canvas.add(img);
        });
    };

    const drawCreatedSquare = (x, y, w, h) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        // ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'rgba(142,6,147, 1)';
        // ctx.fillRect(x, y, w, h);
        ctx.font = "25px serif";
        ctx.fillText("Hello World!", x, h);
        // setPosition({x: startX, y: startY, h: endX - startX, w: endY - startY});

    };

    const drawSquare = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'rgba(142,6,147, 0.3)';
        const x = startX < endX ? startX : startX - endX;
        const y = startY < endY ? startY : startY - endY;
        //   ctx.fillRect(x, y, squareSize, squareSize);
        ctx.fillRect(startX, startY, endX - startX, endY - startY);
        // setPosition({x: startX, y: startY, h: endX - startX, w: endY - startY});

    };
    return (
        <>
            <canvas
                ref={canvasRef}
                onLoad={handleLoadCanvas}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                style={{
                    position: 'absolute',
                    borderColor: 'solid black',
                    marginTop:915
                }}
                // width={width}
                // height={height}
            />
            {/* <img src={src} height={height} alt="My Image" /> */}
        </>
    );
}

export default DragAndDropFormField
