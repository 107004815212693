import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { useState } from 'react';

function ImageWithText({ setOpen, forms, setPosition } = setOpen) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);
  const canvasRef = useRef(null);

  let x = 0;
  let y = 0;
  let w = 0;
  let h = 0;
  
  const handleMouseDown = (event, canvas) => {
    const { offsetX, offsetY } = event;
    setIsMouseDown(true);
    setStartX(offsetX);
    x = offsetX;
    y = offsetY;
    setStartY(offsetY);
    // console.log(isMouseDown);
  };

  const handleMouseMove = (event, canvas) => {
    if (!isMouseDown) return;
    const { offsetX, offsetY } = event;
    setEndX(offsetX);
    setEndY(offsetY);
    // drawSquare(canvas);
  };

  const handleMouseUp = (event, canvas) => {
    const { layerX, layerY } = event;
    setIsMouseDown(false);
    setEndX(layerX);
    w = layerX
    h = layerY
    setEndY(layerY);
    drawSquare(canvas);
    // setPosition({ x: startX, y: startY, w: endX, h: endY })
    // setOpen(true);

  };

  const drawSquare = (canvas) => {
    // const canvas = new fabric.Canvas(canvasRef.current);
    // Add rect to the canvas
    const rect = new fabric.Rect({
      left: x,
      top: y,
      fill: 'red',
      width: w - x,
      height: h - y,
    });

    canvas.add(rect);
    setPosition({ x: x, y: y, w: w - x, h: h - y })
    setOpen(true);


  };
  
  const drawPreviousSquare = (canvas, x,y,z,h) => {
    // const canvas = new fabric.Canvas(canvasRef.current);
    // Add rect to the canvas
    const rect = new fabric.Rect({
      left: x,
      top: y,
      fill: '#8e0693',
      width: z,
      height: h,
    });

    canvas.add(rect);
    // setPosition({ x: x, y: y, w: w - x, h: h - y })
    // setOpen(true);


  };

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current);

    // Load the image
    fabric.Image.fromURL(forms.image, (img) => {
      // Set the image as the canvas background
      canvas.setHeight(img.height);
      canvas.setWidth(img.width)
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
        scaleX: canvas.width / img.width,
        scaleY: canvas.height / img.height,
      });
    });
    if (forms.hasOwnProperty('form_field')) {
      forms.form_field.map((item) => {
            console.log(item);
            drawPreviousSquare(canvas, parseInt(item.x), parseInt(item.y), parseInt(item.w), parseInt(item.h))
          })
        }


    canvas.on({
      'mouse:move': function (event) {
        //  console.log(event);
        handleMouseMove(event.e, canvas)
      },
      'mouse:down': function (event) {
        // console.log('mouse down');
        handleMouseDown(event.e, canvas)
      },
      'mouse:up': function (event) {
        // console.log('mouse up');
        handleMouseUp(event.e, canvas)
      }

    })

  }, []);


  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default ImageWithText;
