import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Print = Loadable(lazy(() => import('./Print')));

const printRoutes = [
  { path: '/print', element: <Print />, auth: authRoles.admin },
];

export default printRoutes;
