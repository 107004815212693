import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import ComputersUpdate from './ComputersUpdate';

const Computers = Loadable(lazy(() => import('./Computers')));

const computerRoutes = [
  { path: '/computers', element: <Computers />, auth: authRoles.admin },
  { path: '/computers/edit/:computerId', element: <ComputersUpdate />, auth: authRoles.admin },
];

export default computerRoutes;
