import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { createSubCategories, getSubCategoryById, updateSubCategories } from "app/api/printNode";
import { Breadcrumb, SimpleCard } from "app/components";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SubCategoriesForm from "./SubCategoriesForm";

const SubCategoriesUpdate = () => {
    const title = 'sub categories';

    const { subCategoryId, type } = useParams();

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [subCategory, setSubCategory] = useState({ name: "", image: null, category_id: "", category: { name: "", id: ""} });
    const [loading, setLoading] = useState(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const currentAdmin = { name: "", image: null, category_id: "", category_name: "" }

    useEffect(() => {
        setLoading(true);
       
        try {
            getSubCategoryById(subCategoryId).then((res) => {
                console.log(res);
            setSubCategory({subCategory, category_id: res.data.result.data.category.id, ...res.data.result.data});
            
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        setSubCategory(error);
        }
      }, [])
    

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("photo", (state.image !== null) ? state.image[0] : "")
        formData.append("category_id", state.category_id)
        
        updateSubCategories(subCategoryId, formData).then((res) => {
            if (type == 1) {
                navigate("/form-sub-categories");
            } else if (type == 2) {
                navigate("/print-sub-categories");
            } else {
                navigate("/link-sub-categories");
            }
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Categories Create" }]} />
            </Box>
            <SimpleCard title="Categories Form">
                <SubCategoriesForm handleSubmit={handleSubmit} tableData={{ name: subCategory.name, category_id: subCategory.category.id, category: subCategory.category, image: null }} type={type} />
            </SimpleCard>
        </Container>
    );
};

export default SubCategoriesUpdate;
