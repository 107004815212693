import { Avatar, Box, Card, Grid, Paper, Typography, styled } from '@mui/material'
import { getActiveCategories } from 'app/api/printNode';
import { MatxLoading } from 'app/components';
import translations from 'app/utils/ar';
import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import enTranslations from '../../utils/en';
import arTranslations from '../../utils/ar';
import { LanguageContext } from 'app/contexts/LanguageContext';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function CategoriesGrid(props) {

    const { type } = props
    const { printerId } = useParams();
    const [loading, setLoading] = useState(false);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const translations = (language == 'en') ? enTranslations : arTranslations;


    useState(() => {
        setLoading(true);
        getActiveCategories(type).then((res) => {
            setCategoriesOptions(res.data.result.data);
            setLoading(false);
        });
        return () => {
            setCategoriesOptions({});
            setLoading(false);

        }
    }, []);

    const handleClick = (id) => {
        console.log(id);
        const url = `/print-manual/${printerId}/${id}/${type}`;
        navigate(url);
    }


    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            {/* <Grid container item spacing={3}>
                <FormRow />
            </Grid> */}
            <Box key={0}>
                <center>
                    <Typography
                        variant="h3"
                        sx={{
                            paddingBottom: '60px'
                        }}
                    >
                        {translations.category}
                    </Typography>
                </center>
            </Box>
            <Grid
                container
                item
                gap={'20px'}
                spacing={5}
                justifyContent="center"
                alignItems="stretch"
                        sx={{
                            margin: '0',
                            textAlign: 'center',
                            width: '100%'
                        }}
            >
                { (loading) ? <MatxLoading/> : 
                    categoriesOptions.map((item) => {
                        // console.log(item)
                        return <Grid
                            item
                            xs={4}
                            sx={{
                                border: '1px solid #495057',
                                padding: '25px 15px !important',
                                textAlign: 'center'
                            }} key={item.id} onClick={() => handleClick(item.id)}>
                            <Avatar
                                variant="square"
                                alt={item.name}
                                src={item.image}
                                sx={{ 
                                    width: 280, 
                                    maxWidth: '100%', 
                                    height: 'auto', 
                                    marginInline: 'auto', 
                                    marginBottom: 2 
                                }}
                            />
                            <Typography
                                style={{ color: 'black', fontWeight: 600, fontSize: 14 }}
                                variant="p"
                            >
                                {item.name}
                            </Typography>
                        </Grid>
                    })
                }
            </Grid>
        </Grid>

        // 


    )
}

export default CategoriesGrid
