import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import CategoriesCreate from './CategoriesCreate';
import CategoriesUpdate from './CategoriesUpdate';

const Categories = Loadable(lazy(() => import('./Categories')));

const categoryRoutes = [
  { path: '/form-categories', element: <Categories type = {1} />, auth: authRoles.admin },
  { path: '/print-categories', element: <Categories type = {2} />, auth: authRoles.admin },
  { path: '/link-categories', element: <Categories type = {3} />, auth: authRoles.admin },
  { path: '/categories/create/:type', element: <CategoriesCreate />, auth: authRoles.admin },
  { path: '/categories/edit/:categoryId/:type', element: <CategoriesUpdate />, auth: authRoles.admin },

];

export default categoryRoutes;
