import axiosClient from './axios';
import axiosServerClient from './axiosServer';

export const whoAmI = () => axiosClient.get('whoami');

export const getPrinters = () => axiosClient.get('printers');

export const deleteComputers = (computers) => axiosClient.delete('computers/' + computers);

export const getComputers = () => axiosClient.get('computers');
export const getComputerPrinters = (id) => axiosClient.get(`/computers/${id}/printers`);

export const getPrintjobs = () => axiosClient.get('printjobs');

export const createPrintjobs = (payload) => axiosClient.post('printjobs', payload);

export const getSettings = () => axiosServerClient.get('print-price');

export const updateSettings = (payload) =>
  axiosServerClient.put('print-price/1', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

// categories apis
export const getCategories = (type) => axiosServerClient.get(`category?status=1&type=${type}`);
export const getActiveCategories = (type) => axiosServerClient.get(`category?type=${type}`  );
export const getCategoryById = (id) => axiosServerClient.get('category/' + id + '?status=1');
export const getActiveCategoryById = (id) => axiosServerClient.get('category/' + id);
export const createCategories = (payload) => axiosServerClient.post('category', payload);
export const updateCategories = (id, payload) =>
  axiosServerClient.post('category/' + id + '?_method=PUT', payload);
export const categoriesUpdateStatus = (id) =>
  axiosServerClient.post('category-status/' + id + '?_method=PUT');
export const deleteCategoriesApi = (id) => axiosServerClient.delete('category/' + id);

// sub categories apis
export const getSubCategories = (type) => axiosServerClient.get(`sub-category?status=1&type=${type}`);
export const getActiveSubCategories = () => axiosServerClient.get('sub-category');
export const getSubCategoryById = (id) => axiosServerClient.get('sub-category/' + id + '?status=1');
export const getActiveSubCategoryById = (id) => axiosServerClient.get('sub-category/' + id);
export const createSubCategories = (payload) => axiosServerClient.post('sub-category', payload);
export const updateSubCategories = (id, payload) =>
  axiosServerClient.post('sub-category/' + id + '?_method=PUT', payload);
export const subCategoriesUpdateStatus = (id) =>
  axiosServerClient.post('sub-category-status/' + id + '?_method=PUT');
export const deleteSubCategoriesApi = (id) => axiosServerClient.delete('sub-category/' + id);

// form
export const getForms = (type) => axiosServerClient.get(`form?status=1&type=${type}`);
export const getFormById = (id) => axiosServerClient.get('form/' + id + '?status=1');
export const createForm = (payload) => axiosServerClient.post('form', payload);
export const updateForm = (id, payload) =>
  axiosServerClient.post('form/' + id + '?_method=PUT', payload);
export const FormUpdateStatus = (id) =>
  axiosServerClient.post('form-status/' + id + '?_method=PUT');
export const deleteFormApi = (id) => axiosServerClient.delete('form/' + id);

// link
export const getLinks = () => axiosServerClient.get(`link?status=1`);
export const getLinkById = (id) => axiosServerClient.get('link/' + id + '?status=1');
export const createLink = (payload) => axiosServerClient.post('link', payload);
export const updateLink = (id, payload) =>
  axiosServerClient.post('link/' + id + '?_method=PUT', payload);
export const LinkUpdateStatus = (id) =>
  axiosServerClient.post('link-status/' + id + '?_method=PUT');
export const deleteLinkApi = (id) => axiosServerClient.delete('link/' + id);

// computer Label
export const getComputerLabel = () => axiosServerClient.get('computer');
export const getComputerById = (id) => axiosServerClient.get('computer/' + id);
export const createComputerApi = (payload) => axiosServerClient.post('newcomputer', payload);
export const updateComputer = (id, payload) =>
  axiosServerClient.post('computer/' + id + '?_method=PUT', payload);
export const deleteComputer = (id) => axiosServerClient.delete('computer/' + id);

export const convertToPdf = (payload) =>
  axiosServerClient.post('img-to-pdf', payload, {
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
    },
  });

export const printFile = (payload) =>
  axiosServerClient.post('print-file', payload, {
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
    },
  });

export const createFormfield = (payload) => axiosServerClient.post('form-field', payload, {
  headers: {
    'Content-Type': 'application/json',
    'access-control-allow-origin': '*',
  },
});
export const deleteFormfield = (id) => axiosServerClient.delete('form-field/' + id);

export const createUserForm = (payload) =>
  axiosServerClient.post('user-form-details', payload, {
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
    },
  });
  export const getUserFormDetails = (id) => axiosServerClient.get('user-form-details/' + id);

  export const addUserValue = (payload) =>
  axiosServerClient.post('user-form', payload, {
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
    },
  });
export const getUserValue = (id) => axiosServerClient.delete('user-form/' + id);
