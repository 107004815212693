import { Avatar, Box, Card, Grid, Paper, Typography, styled } from '@mui/material'
import { getActiveCategoryById } from 'app/api/printNode';
import { MatxLoading } from 'app/components';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function SubCategoriesGrid() {

    const { printerId, categoryId, type } = useParams();
    const [loading, setLoading] = useState(false);
    const [subCategoryName, setSubCategoryName] = useState('sub categories');
    const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
    const navigate = useNavigate();



    useState(() => {
        setLoading(true);
        getActiveCategoryById(categoryId).then((res) => {
            console.log(res.data.result.data.name);
            setSubCategoryName(res.data.result.data.name);
            setSubCategoriesOptions(res.data.result.data.sub_categories);
            setLoading(false);
        });
        return () => {
            setSubCategoriesOptions({});
            setLoading(false);

        }
    }, []);

    const handleClick = (id) => {
        if (type == 3) {
            const url = `/link/${id}`;
            navigate(url);
        } else {
            console.log(id);
            const url = `/form/${printerId}/${id}`;
            navigate(url);
        }
    }


    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >

            <Box key={0}>

                <center>
                    <Typography
                        sx={{
                            paddingBottom: '60px'
                        }}
                        variant="h4"
                    >
                        {subCategoryName}
                    </Typography>
                </center>
            </Box>
            <Grid
                container
                item
                gap={'20px'}
                spacing={5}
                justifyContent="center"
                alignItems="stretch"
                        sx={{
                            margin: '0',
                            textAlign: 'center',
                            width: '100%'
                        }}

            >
                {
                    subCategoriesOptions.map((item) => {
                        // console.log(item)
                        return <Grid
                            item
                            xs={4}
                            sx={{
                                border: '1px solid #495057',
                                padding: '25px 15px !important',
                                textAlign: 'center'
                            }} key={item.id} onClick={() => handleClick(item.id)}>
                            <Avatar
                                variant="square"
                                alt={item.name}
                                src={item.image}
                                sx={{ 
                                    width: 280, 
                                    maxWidth: '100%', 
                                    height: 'auto', 
                                    marginInline: 'auto', 
                                    marginBottom: 2 
                                }}
                            />
                            <Typography
                                style={{ color: 'black', fontWeight: 600, fontSize: 14 }}
                                variant="p"
                            >
                                {item.name}
                            </Typography>
                        </Grid>
                    })
                }
            </Grid >
        </Grid >
    )
}

export default SubCategoriesGrid
