import {
    Button,
    Grid,
    Icon,
    styled,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { useState } from "react";
import FileUpload from "react-material-file-upload";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));


const ComputersForm = ({ handleSubmit, tableData }) => {

    const [state, setState] = useState({ label: tableData.label });
    const handleChange = (event) => {
        
        setState({ ...state, [event.target.name]: event.target.value });
        // console.log(state);
    };


    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>

                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="text"
                            name="label"
                            id="label"
                            value={state.label || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Label"
                            validators={["required"]}
                        />
                    </Grid>
                   

                </Grid>

                <Button color="primary" variant="contained" type="submit" sx={{mt:2}}>
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default ComputersForm;
