import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import { H4, H5 } from 'app/components/Typography';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import enTranslations from '../../../utils/en';
import arTranslations from '../../../utils/ar';
import { LanguageContext } from 'app/contexts/LanguageContext';

const DialogRoot = styled('div')(({ theme }) => ({
  '& form': {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    width: 'fit-content',
  },
  '& .formControl': {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  '& .formControlLabel': {
    marginTop: theme.spacing(1),
  },
}));

export default function MaxWidthDialog({ open, setIsOpen, state, setting, files, printerId, isForm }) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const translations = (language == 'en') ? enTranslations : arTranslations;


  function handleClickOpen() {
    setIsOpen(true);
  }
  function handleClose() {
    setIsOpen(false);
    navigate('/print-form/' + printerId)
    console.log(open);
  }
  function handleMaxWidthChange(event) {
    setMaxWidth(event.target.value);
  }
  function handleFullWidthChange(event) {
    setFullWidth(event.target.checked);
  }

  return (
    <DialogRoot>

      <Dialog
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" color="success" textAlign="center">
          {translations.printing_will_now_take_place_please_take_the_papers_when_the_printing_process_is_finished}
        </DialogTitle>
        {
          (isForm) ?
            <DialogContent>
              <DialogContentText style={{ direction: 'rtl' }}>
                <h3 style={{ textAlign: 'center', fontSize: '24px', margin: '0' }}>
                  <b style={{ color: '#920497' }}>{translations.summary}</b>
                </h3>
                <H5
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    direction: (language == 'en') ? 'ltr' : 'rtl'
                  }}
                >
                  <b style={{ color: '#920497' }}>{translations.color}</b>
                  <b>{state.color}</b>
                </H5>
                <H5
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    direction: (language == 'en') ? 'ltr' : 'rtl'
                  }}>
                  <b style={{ color: '#920497' }}>{translations.copy}</b> {state.count}
                </H5>
                <H5
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    direction: (language == 'en') ? 'ltr' : 'rtl'
                  }}>
                  <b style={{ color: '#920497' }}>{translations.the_price_of_one_copy} </b>
                  {state.color == 'gray-scale'
                    ? parseFloat(setting.price_black_and_white)
                    : parseFloat(setting.price_color)}
                  KWD
                </H5>
                <hr />
                <H5
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    direction: (language == 'en') ? 'ltr' : 'rtl'
                  }}>
                  <b style={{ color: '#920497' }}> {translations.final_price} </b>
                  {state.color == 'gray-scale'
                    ? parseFloat(state.count) *
                    parseFloat(setting.price_black_and_white)
                    : parseFloat(state.count) *
                    parseFloat(setting.price_color)
                  } KWD
                </H5>
              </DialogContentText>
            </DialogContent>
            :

            <DialogContent>
              <DialogContentText style={{ direction: 'rtl' }}>
                <h3 style={{ textAlign: 'center', fontSize: '24px', margin: '0' }}>
                  <b style={{ color: '#920497' }}>{translations.summary}</b>
                </h3>
                <H5
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    direction: (language == 'en') ? 'ltr' : 'rtl'
                  }}>
                  <b style={{ color: '#920497' }}>{translations.color}</b>
                  {state.color}
                </H5>
                <H5 style={{ 
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  direction: (language == 'en') ? 'ltr' : 'rtl'
                   }}>
                  <b style={{ color: '#920497' }}>{translations.number_of_pages}</b> {state.number}
                </H5>
                <H5
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    direction: (language == 'en') ? 'ltr' : 'rtl'
                  }}
                >
                  <b style={{ color: '#920497' }}> {translations.copy}</b> {state.count}
                </H5>
                <H5
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    direction: (language == 'en') ? 'ltr' : 'rtl'
                  }}
                >
                  <b style={{ color: '#920497' }}>{translations.the_price_of_one_copy}</b>
                  {state.color == 'gray-scale'
                    ? parseFloat(setting.price_black_and_white)
                    : parseFloat(setting.price_color)}
                  KWD
                </H5>
                <hr />
                <H5
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    direction: (language == 'en') ? 'ltr' : 'rtl'
                  }}>
                  <b style={{ color: '#920497' }}>{translations.final_price}</b>
                  {state.color == 'gray-scale'
                    ? parseFloat(parseFloat(state.count).toFixed(3) *
                      parseFloat(setting.price_black_and_white).toFixed(3) *
                      parseFloat(state.number).toFixed(3)).toFixed(3)
                    : parseFloat(parseFloat(state.count).toFixed(3) *
                      parseFloat(setting.price_color).toFixed(3) *
                      parseFloat(state.number).toFixed(3)).toFixed(3)}
                  KWD
                </H5>
              </DialogContentText>
            </DialogContent>
        }
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translations.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </DialogRoot >
  );
}
