import { Avatar, Box, Card, Grid, Paper, Typography, styled } from '@mui/material'
import { getActiveSubCategoryById } from 'app/api/printNode';
import { MatxLoading } from 'app/components';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

function Link() {

  const { subCategoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [formsOptions, setFormsOptions] = useState([]);
  const navigate = useNavigate();



  useState(() => {
    setLoading(true);
    getActiveSubCategoryById(subCategoryId).then((res) => {
      console.log(res.data.result.data);
      setForm(res.data.result.data.name);
      setFormsOptions(res.data.result.data.links);
      setLoading(false);
    });
    return () => {
      setFormsOptions({});
      setLoading(false);

    }
  }, []);

  const handleClick = (id) => {
    // console.log(id);
    // const url = `/fill-form/${id}/${printerId}`;
    // navigate(url);
  }


  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >

      <Box key={0}>

        <center>
          <Typography
            sx={{
              paddingBottom: '60px'
            }}
            variant="h4"
          >
            {form}
          </Typography>
        </center>
      </Box>
      <Grid
        container
        item
        gap={'20px'}
        spacing={5}
        justifyContent="center"
        alignItems="stretch"
        sx={{
          margin: '0',
          textAlign: 'center',
          width: '100%'
        }}

      >

        {
          formsOptions.map((item) => {
            // console.log(item)
            return <Grid
              item
              xs={4}
              sx={{
                border: '1px solid #495057',
                padding: '25px 15px !important',
                textAlign: 'center'
              }} key={item.id} onClick={() => window.location.replace(item.link)}>

              <Avatar
                variant="square"
                alt={item.name}
                src={item.thumbnail}
                sx={{ width: 60, height: 60, marginInline: 'auto', marginBottom: 2 }}
              />
              <Typography
                style={{ color: 'black', fontWeight: 600 }}
                variant="p"
              >
                {item.name}
              </Typography>

            </Grid>
          })
        }
      </Grid>
    </Grid>

  )
}

export default Link
