import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import LinksCreate from './LinksCreate';
import LinksUpdate from './LinksUpdate';

const Links = Loadable(lazy(() => import('./Links')));

const linksRoutes = [
  { path: '/links', element: <Links />, auth: authRoles.admin },
  { path: '/links/create', element: <LinksCreate />, auth: authRoles.admin },
  { path: '/links/edit/:linkId', element: <LinksUpdate />, auth: authRoles.admin },
  
];

export default linksRoutes;
